import React, { Fragment } from 'react'
import OrganizationsDG from './OrganizationsDG'

const Organizations = () => {

        return (
            <Fragment>
                <h1>Organizations</h1>
                <OrganizationsDG />
            </Fragment>
        )

}

export default Organizations