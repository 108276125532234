import React, {Fragment, useState} from 'react'
import { useDispatch, useSelector } from "react-redux"

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
    Grid,
    TextField,
    Tooltip,
    Button,
    IconButton,
    FormControl,
    InputLabel,
    Select
} from "@mui/material"
import EditSharpIcon from '@mui/icons-material/EditSharp';

// context
import {OrgState, updateRateAsync, clearError } from './orgSlice'

import Separator from '../../widgets/Separator'
import PaperComponent from '../../widgets/PaperComponent'
import ShowAlert from '../../widgets/ShowAlert'

const EditRateCode = ({rateCode}) => {

    const { loading, error, organization } = useSelector(OrgState)

    const dispatch = useDispatch()

    const initialState = {
        open: false,
        display: rateCode.display,
        rate: rateCode.rate,
        isDefault: rateCode.isDefault,
        selectable: rateCode.selectable,
        isActive: rateCode.isActive,
        source: rateCode.source
    }

    const [dialog, setDialog] = useState(initialState)
    let { open, display, rate, isDefault, selectable, isActive, source } = dialog


    const handleClickOpen = () => { setDialog({...dialog, open: true}) }
    const handleClose = () => {
        dispatch(clearError())
        setDialog(initialState)
    }

    const handleSubmit = () => {
        const rateObject = {tenantId:organization.id ,id: rateCode.id, display, rate, isDefault, selectable, isActive, source }
        console.log(rateObject)
        // dispatch(clearError())
        dispatch(updateRateAsync(rateObject))
        setDialog(initialState)
    }
    
    const onChange = e => {
        setDialog({...dialog, [e.target.name]: e.target.value})
    }



if (!loading) return (
    <Fragment>
        <IconButton color='inherit' onClick={handleClickOpen}>
        <Tooltip title='Edit Rate Code'>
            <EditSharpIcon fontSize='small' style={{color: '#939393'}}/>
        </Tooltip>
        </IconButton>                   
 
    <Dialog open={open} id="editModal" onClose={handleClose} aria-labelledby={'form-dialog-title'} fullWidth={true} maxWidth={'sm'} PaperComponent={PaperComponent}>
    <DialogTitle id='form-dialog-title'>Edit Rate Code</DialogTitle>
    <DialogContent>

        <DialogContentText>
            {
                error && <ShowAlert severity={'error'}>{error}</ShowAlert>
            }
        </DialogContentText>

        <Separator />

        <Grid container spacing={2}>
        <Grid item xs={12}>
                <TextField
                    type='text'
                    id='display'
                    name='display'
                    label='Display Text'
                    value={display}
                    onChange={onChange}
                    variant='outlined'
                    fullWidth
                    size={'small'}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    size={'small'}
                    type='number'
                    id='rate'
                    name='rate'
                    label='Rate Amount'
                    value={rate}
                    onChange={onChange}
                    variant='outlined'
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
            <FormControl variant="outlined"  sx={{minWidth: 200}} >
                <InputLabel size={'small'} htmlFor="outlined-age-native-simple">Source</InputLabel>
                <Select
                    native
                    size={'small'}
                    value={source}
                    onChange={onChange}//{(val) => handleChange(val)}
                    label="Source"
                    inputProps={{
                        name: 'source',
                        id: 'source',
                    }}
                    >
                    <option aria-label="" value=""></option>
                    <option aria-label="DR" value="DR">DR</option>
                    <option aria-label='Unit 387' value='Unit 387'>Unit 387</option>
                    <option aria-label={organization.name} value={organization.name}>{organization.name}</option>
                </Select>
            </FormControl>    
            </Grid>
            <Grid item xs={12}>
            <FormControl variant="outlined"  sx={{minWidth: 200}} >
                <InputLabel size={'small'} htmlFor="outlined-age-native-simple">Active?</InputLabel>
                <Select
                    native
                    size={'small'}
                    value={isActive}
                    onChange={onChange}//{(val) => handleChange(val)}
                    label="Active?"
                    inputProps={{
                        name: 'isActive',
                        id: 'isActive',
                    }}
                    >
                    <option value='true'>Yes</option>
                    <option value="false">No</option>
                </Select>
            </FormControl>    
            </Grid>
            <Grid item xs={12}>
            <FormControl variant="outlined"  sx={{minWidth: 200}} >
                <InputLabel size={'small'} htmlFor="outlined-age-native-simple">Default Rate?</InputLabel>
                <Select
                    native
                    size={'small'}
                    value={isDefault}
                    onChange={onChange}//{(val) => handleChange(val)}
                    label="Default Rate?"
                    inputProps={{
                        name: 'isDefault',
                        id: 'isDefault',
                    }}
                    >
                    <option value='true'>Yes</option>
                    <option value="false">No</option>
                </Select>
            </FormControl>    
            </Grid>
            <Grid item xs={12}>
            <FormControl variant="outlined"  sx={{minWidth: 200}} >
                <InputLabel size={'small'} htmlFor="outlined-age-native-simple">Selectable?</InputLabel>
                <Select
                    native
                    size={'small'}
                    value={selectable}
                    onChange={onChange}//{(val) => handleChange(val)}
                    label="Selectable?"
                    inputProps={{
                        name: 'selectable',
                        id: 'selectable',
                    }}
                    >
                    <option value='true'>Yes</option>
                    <option value="false">No</option>
                </Select>
            </FormControl>    
            </Grid>
            
        </Grid>
    </DialogContent>
    <DialogActions>
        <Button onClick={handleClose} color={'primary'}>
            Cancel
        </Button>
        <Button onClick={handleSubmit} color={'primary'}>
            Submit
        </Button>
    </DialogActions>
</Dialog>
</Fragment>
)
else 
        return (<div></div>)
}
export default EditRateCode