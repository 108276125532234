import axios from "axios"
import fileDownload from "js-file-download"
import moment from 'moment'
import React, { useEffect, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// mui
import {IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Chip} from "@mui/material";
import { GetApp, QueryBuilder, Done, Error } from "@mui/icons-material";
import {AWSState, loadBatchesAsync} from './awsSlice'
import Spinner from "../../layouts/Spinner"


const BatchTable = () => {
    const { batches } = useSelector(AWSState)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(loadBatchesAsync())
    }, [])

    const downloadFile = async evt => {
        const importId = evt.currentTarget.getAttribute('data-importid')
        const res = await axios.get(`${process.env.REACT_APP_API_BASE}/v1/aws/${importId}`, {responseType: 'blob'})

        const fileName = res.headers['content-disposition'].split("filename=")[1].replaceAll('"', '')
        fileDownload(res.data, fileName)
    }

    const headers = [
        {
            property: 'batchId', label: 'Batch ID', getValue: function (obj) {
                return obj[this.property]
            }
        },
        {
            property: 'createdAt', label: 'Date', getValue: function (obj) {
                return moment(obj[this.property]).format("MM/DD/YYYY")
            }
        },
        {
            property: 'Organization', label: 'Organization', getValue: function (obj) {
                return obj[this.property].name
            }
        },
        {
            property: 'status', label: 'Upload Status', getValue: function(obj) {
                const status = obj.status
                switch (status) {
                    case 'running':
                        return <Chip icon={<QueryBuilder />} label={status} variant={'outlined'} />
                    case 'complete':
                        return <Chip icon={<Done style={{color: '#2e7d32'}} />} label={status} variant={'outlined'}  />
                    case 'failed':
                        return <Chip icon={<Error style={{color: 'ef5350'}} />} label={status} variant={'outlined'}  />
                    default:
                        return status
                }
            }
        },
        {
            property: 'fileName', label: 'File Name', getValue: function (obj) {
                return <Fragment><IconButton size='small' variant='outlined' data-importid={obj.id}
                                             onClick={downloadFile}><GetApp/></IconButton> {obj[this.property]}
                </Fragment>
            }
        },
        {
            property: 'lines', label: 'Lines in File', getValue: function (obj) {
                return obj[this.property]
            }
        },
        {
            property: 'createdLines', label: 'Lines Created', getValue: function (obj) {
                return obj[this.property]
            }
        },
    ]

    if(batches){
        return <TableContainer component={Paper} style={{marginTop: '30px'}}>

        <Table sx={{minWidth: 650}} size="small">

            <TableHead>
                <TableRow>
                    {headers.map(h => <TableCell key={h.property}>{h.label}</TableCell>)}
                </TableRow>
            </TableHead>
            <TableBody>
                {batches.map(b => {
                    return <TableRow key={b.id} id={b.id}>
                        {headers.map(h => {
                            return <TableCell key={`${b.id}${h.property}`}>{h.getValue(b)}</TableCell>
                        })}
                    </TableRow>
                })
                }
            </TableBody>
        </Table>
    </TableContainer>
    }
    else return <Spinner />
}

export default BatchTable