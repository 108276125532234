import React, {Fragment, useState} from 'react'
import { useDispatch, useSelector } from "react-redux"

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
    Grid,
    TextField,
    Tooltip,
    Button,
    IconButton,
    FormControl,
    InputLabel,
    Select
} from "@mui/material"
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

// context
import {OrgState, addNewUserAsync, clearError, } from './orgSlice'

import Separator from '../../widgets/Separator'
import PaperComponent from '../../widgets/PaperComponent'
import ShowAlert from '../../widgets/ShowAlert'

const AddUser = () => {

    const { loading, error, organization } = useSelector(OrgState)

    const dispatch = useDispatch()

    const initialState = {
        open: false,
        firstName: '',
        lastName: '',
        email: '',
        isSystemAccount: '',
        hgUser: '',

    }

    const [dialog, setDialog] = useState(initialState)
    let { open, firstName, lastName, email, isSystemAccount, hgUser } = dialog


    const handleClickOpen = () => { setDialog({...dialog, open: true}) }
    const handleClose = () => {
        dispatch(clearError())
        setDialog(initialState)
    }

    const handleSubmit = () => {
        const user = {organizationId: organization.id,firstName, lastName, email, isSystemAccount, hgUser  }
        console.log(user)
        // dispatch(clearError())
        dispatch(addNewUserAsync(user))
        setDialog(initialState)
    }
    
    const onChange = e => {
        setDialog({...dialog, [e.target.name]: e.target.value})
    }



if (!loading) return (
    <Fragment>
        <IconButton color='inherit' onClick={handleClickOpen}>
        <Tooltip title='Add User'>
            <AddCircleOutlineIcon fontSize='medium' style={{color: '#000032'}}/>
        </Tooltip>
        </IconButton>                   
 
    <Dialog open={open} id="addModal" onClose={handleClose} aria-labelledby={'form-dialog-title'} fullWidth={true} maxWidth={'sm'} PaperComponent={PaperComponent}>
    <DialogTitle id='form-dialog-title'>Add User</DialogTitle>
    <DialogContent>

        <DialogContentText>
            {
                error && <ShowAlert severity={'error'}>{error}</ShowAlert>
            }
        </DialogContentText>

        
        {/* <DialogContentText>Please select a start and end date.</DialogContentText> */}
        {/* <DialogContentText>End date is bill to, will not include queries made on that date.</DialogContentText> */}
        <Separator />
        <Grid container spacing={2}>
        <Grid item xs={12}>
                <TextField
                    type='text'
                    id='firstName'
                    name='firstName'
                    label='First Name'
                    value={firstName}
                    onChange={onChange}
                    variant='outlined'
                    fullWidth
                    size={'small'}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    size={'small'}
                    type='text'
                    id='lastName'
                    name='lastName'
                    label='Last Name'
                    value={lastName}
                    onChange={onChange}
                    variant='outlined'
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    size={'small'}
                    type='text'
                    id='email'
                    name='email'
                    label='Email'
                    value={email}
                    onChange={onChange}
                    variant='outlined'
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
            <FormControl variant="outlined"  sx={{minWidth: 200}} >
                <InputLabel size={'small'} htmlFor="outlined-age-native-simple">System Account</InputLabel>
                <Select
                    native
                    size={'small'}
                    value={isSystemAccount}
                    onChange={onChange}//{(val) => handleChange(val)}
                    label="System Account"
                    inputProps={{
                        name: 'isSystemAccount',
                        id: 'isSystemAccount',
                    }}
                    >
                    <option aria-label="None" value="" />
                    <option value='true'>Yes</option>
                    <option value="false">No</option>
                </Select>
            </FormControl>    
            </Grid>
            <Grid item xs={12}>
                <TextField
                    size={'small'}
                    type='text'
                    id='hgUser'
                    name='hgUser'
                    label='HG User'
                    value={hgUser}
                    onChange={onChange}
                    variant='outlined'
                    fullWidth
                />
            </Grid>
            
        </Grid>
    </DialogContent>
    <DialogActions>
        <Button onClick={handleClose} color={'primary'}>
            Cancel
        </Button>
        <Button onClick={handleSubmit} color={'primary'}>
            Submit
        </Button>
    </DialogActions>
</Dialog>
</Fragment>
)
else 
        return (<div></div>)
}
export default AddUser