import React, { Fragment } from 'react'
import moment from 'moment'


import Spinner from '../../layouts/Spinner'
import UpdateDocServerStatus from './UpdateDocServerStatus'
import UpdateDocServerProcessing from './UpdateDocServerProcessing'

// MUI
import { Box, Card, Paper, Table, TableHead, TableRow, TableCell, TableBody, TableContainer } from "@mui/material"

import getIcon from '../../widgets/GetIcon'

const DocServers = ({data}) => { 

    if(!data) return <Spinner />
    return <Fragment>
            <Card sx={{ width: 5/8, marginTop: 5, marginBottom:5 }}>
                <Box sx={{ marginLeft: 2, marginBottom: 2}}>
                    <h3>Document Servers</h3>
                    {/* <TableContainer component={Paper} sx={{marginBottom: 5, paddingRight:2}} > */}
                    <Table sx={{ minWidth: 650, marginRight:2 }} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left"></TableCell>
                                {/* <TableCell align="left"></TableCell> */}
                                <TableCell align="left">Host Name</TableCell>
                                <TableCell align="left">AWS Instance</TableCell>
                                <TableCell align="left">Active?</TableCell>
                                <TableCell align="left">Process?</TableCell>
                                <TableCell align="left">Last Update</TableCell>
                                <TableCell align="left">Assigned</TableCell>
                                <TableCell align="left">Processing</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((row) => (
                                <TableRow
                                    key={row.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell align="left">{<Fragment><UpdateDocServerStatus data={row}/> <UpdateDocServerProcessing data={row}/></Fragment>}</TableCell>
                                    {/* <TableCell align="left">{<UpdateDocServerProcessing data={row}/>}</TableCell> */}
                                    <TableCell align="left">{row.hostname}</TableCell>
                                    <TableCell align="left">{row.awsInstanceId}</TableCell>
                                    <TableCell align="left">{getIcon(row.active)}</TableCell>
                                    <TableCell align="left">{getIcon(row.process)}</TableCell>
                                    <TableCell align="left">{row.updatedAt ? moment(row.updatedAt).format('YYYY-MM-DD HH:mm') : ''}</TableCell>
                                    <TableCell align="left">{row.ActiveProcesses.length}</TableCell>
                                    <TableCell align="left">{row.ActiveProcesses.filter(a => a.pid !== null).length}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                {/* </TableContainer> */}
                </Box>
            </Card>
        </Fragment>

}

export default DocServers