import React, {Fragment, useState} from 'react'
import { useDispatch, useSelector } from "react-redux"

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
    Tooltip,
    Button,
    IconButton
} from "@mui/material"
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';


// context
import {BillingState, undoBillRunAsync, clearError, } from './billingSlice'

import Separator from '../../widgets/Separator'
import PaperComponent from '../../widgets/PaperComponent'
import ShowAlert from '../../widgets/ShowAlert'

const UndoBillRun = ({billRun}) => {

    const { loading, error } = useSelector(BillingState)

    const dispatch = useDispatch()


    const initialState = {
        open: false,
    }

    const [dialog, setDialog] = useState(initialState)
    let { open } = dialog


    const handleClickOpen = () => { setDialog({...dialog, open: true}) }
    const handleClose = () => {
        dispatch(clearError())
        setDialog(initialState)
    }

    const handleSubmit = () => {
        console.log('Submit Undo for Run: '+ billRun.id)

        dispatch(clearError())
        dispatch(undoBillRunAsync(billRun.id))
        setDialog(initialState)
    }
    



if (!loading) return (
    <Fragment>
        <IconButton color='inherit' onClick={handleClickOpen}>
        <Tooltip title='Undo Bill Run'>
            <DeleteForeverIcon style={{color: '#000032'}}/>
        </Tooltip>
        </IconButton>                   
 
    <Dialog open={open} id="undoModal" onClose={handleClose} aria-labelledby={'form-dialog-title'} fullWidth={true} maxWidth={'sm'} PaperComponent={PaperComponent}>
    <DialogTitle id='form-dialog-title'>Undo Bill Run</DialogTitle>
    <DialogContent>

        <DialogContentText>
            {
                error && <ShowAlert severity={'error'}>{error}</ShowAlert>
            }
        </DialogContentText>

        
        <DialogContentText>You are about to undo bill run {billRun.id}</DialogContentText>
        <DialogContentText>Do you wish to continue?</DialogContentText>
        <Separator />
    </DialogContent>
    <DialogActions>
        <Button onClick={handleClose} color={'primary'}>
            Cancel
        </Button>
        <Button onClick={handleSubmit} color={'primary'}>
            Submit
        </Button>
    </DialogActions>
</Dialog>
</Fragment>
)
else 
        return (<div></div>)
}
export default UndoBillRun