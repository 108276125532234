import React, { useEffect, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// MUI
import { Grid } from "@mui/material"

import {getAppHealthDataAsync, AppHealthState} from './appHealthSlice'
import DocumentDownloads from './DocumentDownloads'
import SystemAlarms from './SystemAlarms'
import DocServers from './DocServers'

import Spinner from "../../layouts/Spinner"

const AppHealth = () => {
    const { data } = useSelector(AppHealthState)

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getAppHealthDataAsync())
    }, [dispatch])

    if(!data) return <Fragment>
                        <h1>Application Health Data</h1>
                        <Spinner />
                    </Fragment>
    return (
        <Fragment>
            <h1>Application Health Data</h1>
            <Grid container>
                <DocumentDownloads data={data?.DownloadData}/>
                <SystemAlarms data={data?.HealthLog} />
            </Grid>
            <Grid container>
                <DocServers data={data?.DocServer}/>
            </Grid>
        </Fragment>
        )

}

export default AppHealth