import React, { useEffect, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import moment from 'moment'

// mui
import { Grid } from '@mui/material'

import { BillingState, getBillRunAsync} from './billingSlice'
import Bill from './Bill'
import Spinner from '../../layouts/Spinner'

import Label from '../../widgets/Label'

const BillRunDetail = () => {
    const { id } = useParams()
    const dispatch = useDispatch()
    //Destructure of People State elements
    const {billRun } = useSelector(BillingState)

    useEffect(() => {
        dispatch(getBillRunAsync(id))
    }, [dispatch, id])
 

       if(billRun){
                return (
                    <Fragment>
                        <h1>Bill Run Detail</h1>
                        <Grid item xs={12} sm={2}>
                            <Label text={`Bill Run: ${billRun.id}`} />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Label text={`Bill Date: ${moment(billRun.createdAt).format('YYYY-MM-DD')}`} />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Label text={`Bill Period: ${moment(billRun.startDate).format('YYYY-MM-DD')} to ${moment(billRun.endDate).format('YYYY-MM-DD')}`}  />
                        </Grid>
                        <h3>Bills</h3>
                        {billRun && billRun.Bills &&
                            <div>
                                {
                                    <Bill bills={billRun.Bills} />
                                }
                            </div>
                        }
                    </Fragment>
                )
            }
        else return <Spinner />

}

export default BillRunDetail