import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios'

export const slice = createSlice({
    name: 'aws',
    initialState: {
        loading: false,
        records: null,
        person: null,
        error: null,
        batches: null,
    },
    reducers: {
        setBatches: (state, action) => {
            state.batches = action.payload
            state.loading = false
        },
        loadRecords: (state, action) => {
            state.records = action.payload.records
            state.person = action.payload.person
            state.loading = false
        },
        loadFolders: (state, action) => {
            state.folders = action.payload
            state.loading = false
        },
        setLoading: (state) => {
            state.loading = false
        },
        setError: (state, action) => { state.error = action.payload},
        clearError: state => { state.error = null },
    }
})

export const { setLoading, setBatches, loadRecords, setError, clearError, loadFolders } = slice.actions

export const loadBatchesAsync = () => async dispatch => {
    dispatch(setLoading())
    const config = {headers: {'Content-Type': 'application/json'}}
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_BASE}/v1/aws`, config)
        dispatch(setBatches(res.data))
    } catch (err) {
        dispatch(setError(err.response.data.error))
    }
}

export const getRecordsAsync = (id) => async dispatch => {
    // dispatch(setLoading())
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_BASE}/v1/Aws/${id}`)
        dispatch(loadRecords(res.data))
    } catch (err) {
        console.log(err)
        // dispatch(setError(err.response.data.error))
    }
}

// export const getFoldersAsync = (id) => async dispatch => {
//     // dispatch(setLoading())
//     try {
//         const res = await axios.get(`${process.env.REACT_APP_API_BASE}/v1/Aws/${id}/$folders` )
//         dispatch(loadFolders(res.data))
//     } catch (err) {
//         console.log(err)
//         // dispatch(setError(err.response.data.error))
//     }
// }


export const AWSState = state => state.aws

export default slice.reducer