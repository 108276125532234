import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

// state
import {getDocumentDownloadDetailsAsync, AppHealthState} from './appHealthSlice'

import Spinner from '../../layouts/Spinner'
import Separator from '../../widgets/Separator'

// MUI
import { DataGrid, 
    GridToolbar,
    getGridStringOperators
 } from '@mui/x-data-grid';

import { Button,
    Grid,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle
} from "@mui/material"

import Label from '../../widgets/Label'

const DocumentDownloadDetails = () => {
    const dispatch = useDispatch()
    //Destructure of People State elements
    const { downloadDetails } = useSelector(AppHealthState)

    useEffect(() => {
        dispatch(getDocumentDownloadDetailsAsync())
    }, [dispatch])

    const initialState = {
        open: false
    }

    const [dialog, setDialog] = useState(initialState)
    let { open } = dialog


    const handleClickOpen = () => { setDialog({...dialog, open: true}) }
    const handleClose = () => {
        // dispatch(clearError())
        setDialog(initialState)
    }

    const columns = React.useMemo( () => {
        const columnsFormat = [
            {field: 'organization', headerName: 'Organization', width: 250,
                renderCell: (params) => {
                    return params.row.Organization.name
                }},
            {field: 'searchName', headerName: 'Patient', width: 250,
                renderCell: (params) => {
                    return params.row.searchName
                }},
            {field: 'createdAt', headerName: 'DateTime Added', width: 200,
                renderCell: (params) => {
                    return params.row.DownloadAsync[0].createdAt ? moment(params.row.DownloadAsync[0].createdAt).format('YYYY-MM-DD HH:mm') : ''
                }}, 
            {field: 'attempts', headerName: 'Attempts', width: 150,
                renderCell: (params) => {
                    return params.row.DownloadAsync[0].attempts > 0 ? params.row.DownloadAsync[0].attempts : 0
                }},
            {field: 'lastAttempt', headerName: 'Last Attempt', width: 200,
                renderCell: (params) => {
                    return params.row.DownloadAsync[0].lastAttempt ? moment(params.row.DownloadAsync[0].lastAttempt).format('YYYY-MM-DD HH:mm') : ''
                }}, 
        ]

        return columnsFormat.map((col) => {
            return {
              ...col,
              filterOperators: getGridStringOperators().filter(
                (operator) => operator.value === 'contains' || operator.value === 'equals',
              ),
            };
          })
    }, []);

    if(downloadDetails) {
        return (   <Fragment>
            <Button color='inherit' onClick={handleClickOpen}>
                <Label text={`View Details`} />
            </Button>                   
     
        <Dialog open={open} id="downloadDetailsModal" onClose={handleClose} aria-labelledby={'form-dialog-title'} fullWidth={true} maxWidth={'lg'} >
        <DialogTitle id='form-dialog-title'>Document Download Details</DialogTitle>
        <Separator />
        <DialogContent>

            <Grid container spacing={2}>
            <div style={{ height: 650, width: '100%', marginLeft:5 }}>
                    <DataGrid
                        components={{
                            Toolbar: GridToolbar
                        }}
                        componentsProps={{
                            toolbar: {
                              csvOptions: { disableToolbarButton: true },
                              printOptions: { disableToolbarButton: true },
                            },
                        }}
                        style={{
                            backgroundColor: 'white'
                        }}
                        rows={downloadDetails}
                        columns={columns}
                        rowsPerPageOptions={[25,50,100]}
                        initialState={{
                            sorting: {
                              sortModel: [{ field: 'createdAt', sort: 'asc' }],
                            },
                          }}
                        
                    />
                </div>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} color={'primary'}>
                Close
            </Button>
        </DialogActions>
    </Dialog>
    </Fragment>
        )
        }
    // If state people array is not populated this returns
        else
            return <Spinner />
}


export default DocumentDownloadDetails