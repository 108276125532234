import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios'

export const slice = createSlice({
    name: 'rateChanges',
    initialState: {
        loading: false,
        pendingRequests: null,
        // rates: [],
        error: null
    },
    reducers: {
        loadPendingRequests: (state, action) => {
            state.pendingRequests = action.payload
            state.loading = false
        },
        setLoading: (state) => {
            state.loading = true
        },
        setError: (state, action) => { state.error = action.payload},
        clearError: state => { state.error = null },
    }
})

export const { setLoading, loadPendingRequests, setError, clearError } = slice.actions

export const getPendingRequestsAsync = () => async dispatch => {
    dispatch(setLoading())
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_BASE}/v1/RateChanges/$Pending`)
        dispatch(loadPendingRequests(res.data))
    } catch (err) {
        console.log(err)
        // dispatch(setError(err.response.data.error))
    }
}

export const resolveRateChangeRequestAsync = ({id,resolution}) => async dispatch => {
    dispatch(setLoading())
    try {
        await axios.put(`${process.env.REACT_APP_API_BASE}/v1/RateChanges`,{id,resolution})
        dispatch(getPendingRequestsAsync())
    } catch (err) {
        console.log(err)
        // dispatch(setError(err.response.data.error))
    }
}


export const RateChanges = state => state.rateChanges

export default slice.reducer