import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'

import moment from 'moment'

// mui
import { Paper, Table, TableHead, TableRow, TableCell, TableBody, TableContainer, Typography, IconButton, Collapse, Box } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

// components
import Spinner from '../../layouts/Spinner'

// context
import { OrgState } from './orgSlice'
import EditUser from './EditUser'
import AddUser from './AddUser'

import getIcon from '../../widgets/GetIcon'

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
  
    return (
      <Fragment>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell size='small' >
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell size='small' width={5}> <EditUser user={row}/> </TableCell>
          <TableCell size='small' component="th" scope="row">
            {row.fullName}
          </TableCell>
          <TableCell size='small' align="left">{row.email}</TableCell>
          <TableCell size='small' align="left">{getIcon(row.isActive)}</TableCell>
          <TableCell size='small' align="left">{getIcon(row.isSystemAccount)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Table size="small" aria-label="details">
                  <TableHead>
                    <TableRow>
                      <TableCell size='small' align="left">User ID</TableCell>
                      <TableCell size='small' align="left">Created Date</TableCell>
                      <TableCell size='small' align="left">HG User</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                      <TableRow key={row.id}>
                        <TableCell size='small' align="left">{row.id}</TableCell>
                        <TableCell size='small' align="left" component="th" scope="row">
                          {moment(row.createdAt).format('YYYY-MM-DD')}
                        </TableCell>
                        <TableCell size='small' align="left">{row.hgUser}</TableCell>
                      </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </Fragment>
    );
  }


const Users = ({organization}) => {
    const { Users } = organization

    const { loading } = useSelector(OrgState)

    const Title = () => {
        return <Typography variant={'h6'} style={{marginTop: '30px'}} >Users</Typography>
    }

    if (loading) return (
        <Fragment>
            <Title />
            <Spinner />
        </Fragment>
    )


    return (
        <Fragment>
            <Title />
            <AddUser  />
            {Users && Users.length === 0 && <p>No Users</p>}
            {Users && Users.length > 0 && (
                <TableContainer component={Paper} sx={{marginBottom:5, maxWidth:800}} options={{
                    padding: "dense",
                  }}>
                <Table aria-label="collapsible table" >
                  <TableHead>
                    <TableRow>
                                 <TableCell size='small' ></TableCell>
                                 <TableCell size='small'></TableCell>
                                 <TableCell size='small' align="left">Name</TableCell>
                                 <TableCell size='small' align="left">Email</TableCell>
                                 <TableCell size='small' align="left">Active</TableCell>
                                 <TableCell size='small' align='left'>System Account</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Users.map((row) => (
                      <Row key={row.id} row={row} />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
        </Fragment>

    )
}

export default Users