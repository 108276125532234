import React, { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

// state
import { OrgState, getOrgsAsync} from './orgSlice'

import Spinner from '../../layouts/Spinner'

// MUI
import { DataGrid, 
    GridToolbar,
    getGridStringOperators
 } from '@mui/x-data-grid';

import { 
    Box,
    Link,
    Tooltip
} from "@mui/material"

import DetailsIcon from '@mui/icons-material/Details';
import AddOrganization from './AddOrganization'

import getIcon from '../../widgets/GetIcon'

const OrganizationsDG = () => {
    const dispatch = useDispatch()
    //Destructure of People State elements
    const { orgs } = useSelector(OrgState)

    useEffect(() => {
        dispatch(getOrgsAsync())
    }, [])
 

    //Defines People Data Grid columns
    const columnsFormat = [
        {field: 'viewDetails', headerName: '', width: 50, sortable: false, align: 'center',filterable: false,
            renderCell: (params) => {
                return <Link href={"/organizations/"+ params.row.id}><Tooltip title='View Details'><DetailsIcon /></Tooltip></Link>
            }},
        {field: 'name', headerName: 'Name', width: 250,
            renderCell: (params) => {
                return params.row.name
            }},
        {field: 'createdAt', headerName: 'Date Added', width: 150,
            renderCell: (params) => {
                return moment(params.row.createdAt).isValid() ? moment(params.row.createdAt).format('YYYY-MM-DD') : ''
            }}, 
        {field: 'isActive', headerName: 'Status', width: 150,
            renderCell: (params) => {
                return getIcon(params.row.isActive)
            }}
        
    ]

    const columns = React.useMemo( () =>
          columnsFormat.map((col) => {
            return {
              ...col,
              filterOperators: getGridStringOperators().filter(
                (operator) => operator.value === 'contains' || operator.value === 'equals',
              ),
            };
          }),
        [columnsFormat],
      );

    if(orgs.length > 0 ) {
        return (<Fragment>
            <div>
                <Box display='flex' justifyContent='flex-end'>
                    <AddOrganization/>
                </Box>
                <div style={{ height: 650, width: '100%' }}>
                    <DataGrid
                        components={{
                            Toolbar: GridToolbar
                        }}
                        componentsProps={{
                            toolbar: {
                              csvOptions: { disableToolbarButton: true },
                              printOptions: { disableToolbarButton: true },
                            },
                        }}
                        style={{
                            backgroundColor: 'white'
                        }}
                        rows={orgs}
                        columns={columns}
                        rowsPerPageOptions={[25,50,100]}
                        initialState={{
                            sorting: {
                              sortModel: [{ field: 'name', sort: 'asc' }],
                            },
                          }}
                        
                    />
                </div>
            </div>
            </Fragment>
        )
        }
    // If state people array is not populated this returns
        else
            return <Spinner />
}


export default OrganizationsDG