import React, { Fragment } from 'react'
import RateChangesTable from './RateChangesTable'

const RateChanges = () => {

        return (
            <Fragment>
                <h1>Pending Rate Changes</h1>
                <RateChangesTable />
            </Fragment>
        )

}

export default RateChanges