import React, { Fragment } from 'react'

import Spinner from '../../layouts/Spinner'

// MUI
import { DataGrid, 
    GridToolbar,
    getGridStringOperators
 } from '@mui/x-data-grid';

import { Link, Tooltip} from "@mui/material"
import { fPercent,fShortenNumber } from '../../utils/formatNumber';

import DetailsIcon from '@mui/icons-material/Details';



const QueriesDG = ({organizations}) => {

    console.log(organizations)

    //Defines People Data Grid columns
    const columnsFormat = [
        {field: 'viewDetails', headerName: '', width: 50, sortable: false, align: 'center',filterable: false,
            renderCell: (params) => {
                return <Link href={"/organizations/"+ params.row.id}><Tooltip title='View Details'><DetailsIcon /></Tooltip></Link>
            }},
        {field: 'name', headerName: 'Name', width: 250,
            renderCell: (params) => {
                return params.row.name
            }},
        {field: 'personCount', headerName: '# Patients', width: 150, type: "number",
            renderCell: (params) => {
                return params.row.personCount
            }}, 
        {field: 'queryCount', headerName: '# Queries', width: 150, type: "number",
            renderCell: (params) => {
                return params.row.queryCount
            }},
        {field: 'returnDataCount', headerName: '# Queries W/ Data', width: 150, type: "number",
            renderCell: (params) => {
                return params.row.returnDataCount
            }},
        {field: 'returnPercent', headerName: 'Return %', width: 150, type: "number", filterable: false, sortable: false,
            renderCell: (params) => {
                return fPercent(params.row.returnDataCount / params.row.queryCount * 100)
            }},
        {field: 'averageRecordCount', headerName: 'Ave Doc Count', width: 150, type: "number",
            renderCell: (params) => {
                return fShortenNumber(params.row.averageRecordCount)
            }},
        {field: 'reciprocalCount', headerName: 'Patient w/ Reciprocal', width: 160, type: "number",
            renderCell: (params) => {
                return fShortenNumber(params.row.reciprocalCount)
            }},
        {field: 'reciprocalPercent', headerName: 'Reciprocal %', width: 150, type: "number", filterable: false, sortable: false,
            renderCell: (params) => {
                return fPercent(params.row.reciprocalCount / params.row.personCount * 100)
            }},
    ]

    const columns = React.useMemo( () =>
          columnsFormat.map((col) => {
            return {
              ...col,
              filterOperators: getGridStringOperators().filter(
                (operator) => operator.value === 'contains' || operator.value === 'equals',
              ),
            };
          }),
        [columnsFormat],
      );

    if(organizations && organizations.length > 0 ) {
        return (<Fragment>
            <div>
                <div style={{ height: 650, width: '100%' }}>
                    <DataGrid
                        components={{
                            Toolbar: GridToolbar
                        }}
                        componentsProps={{
                            toolbar: {
                              csvOptions: { disableToolbarButton: true },
                              printOptions: { disableToolbarButton: true },
                            },
                        }}
                        style={{
                            backgroundColor: 'white'
                        }}
                        rows={organizations}
                        columns={columns}
                        rowsPerPageOptions={[25,50,100]}
                        // initialState={{
                        //     sorting: {
                        //       sortModel: [{ field: 'name', sort: 'asc' }],
                        //     },
                        //   }}
                        
                    />
                </div>
            </div>
            </Fragment>
        )
        }
    // If state people array is not populated this returns
        else
            return <Spinner />
}


export default QueriesDG