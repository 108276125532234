import { configureStore } from '@reduxjs/toolkit';

import monitorReducersEnhancer from "./enhancers/monitorReducers"
import loggerMiddleware from './middleware/logger'
import rootReducer from './reducers'

export default function createStore(preloadedState) {
    const store = configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware().concat(loggerMiddleware),
        enhancers: (defaultEnhancers) =>
            defaultEnhancers.concat(monitorReducersEnhancer),
        preloadedState,
        devTools: process.env.REACT_APP_SERVER_ENV !== 'production'
    });

    return store
}