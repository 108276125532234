import React from 'react'

import { CheckCircle } from '@mui/icons-material'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const getIcon = status => {
    switch (status) {
        case true: return <CheckCircle style={{color: 'green'}} />
        case false: return <HighlightOffIcon style={{color: 'red'}} />
    }
}

export default getIcon