import moment from 'moment'
import React, { useEffect, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// mui
import { IconButton, Paper, Table, TableBody, TableCell,Tooltip, TableContainer, TableHead, TableRow } from "@mui/material";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';

import { RateChanges,getPendingRequestsAsync, resolveRateChangeRequestAsync} from './rateChangeSlice'
import Spinner from "../../layouts/Spinner"


const RateChangesTable = () => {
    const { pendingRequests, loading } = useSelector(RateChanges)

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getPendingRequestsAsync())
    }, [])

    const handleApproval = (id) => { 
        dispatch(resolveRateChangeRequestAsync({id,resolution:'Approved'}))
    }
    const handleDeny = (id) => { 
        dispatch(resolveRateChangeRequestAsync({id,resolution:'Denied'}))
    }

    const headers = [
        {
            property: 'id', label: '', getValue: function (obj) {
                return <Fragment>
                            <IconButton color='inherit' onClick={() => handleApproval(obj[this.property])} >
                                <Tooltip title='Approve'>
                                    <CheckBoxIcon fontSize='small' style={{color: '#32b855'}}/>
                                </Tooltip>
                            </IconButton> 
                            <IconButton color='inherit' onClick={() => handleDeny(obj[this.property])} style={{marginLeft: 5}} >
                                <Tooltip title='Deny'>
                                    <DisabledByDefaultIcon fontSize='small' style={{color: '#f21f2e'}}/>
                                </Tooltip>
                            </IconButton> 
                        </Fragment>
            }
        },
        {
            property: 'User', label: 'Organization', getValue: function (obj) {
                return obj[this.property].organization.name
            }
        },
        {
            property: 'User', label: 'User', getValue: function (obj) {
                return obj[this.property].fullName
            }
        },
        {
            property: 'createdAt', label: 'Date Requested', getValue: function (obj) {
                return moment(obj[this.property]).format("MM/DD/YYYY")
            }
        },
        {
            property: 'Person', label: 'Patient', getValue: function (obj) {
                return obj[this.property].searchName
            }
        },
        {
            property: 'CurrentRate', label: 'Current Rate', getValue: function (obj) {
                return obj[this.property].display
            }
        },
        {
            property: 'NewRate', label: 'New Rate', getValue: function (obj) {
                return obj[this.property].display
            }
        },
        {
            property: 'reason', label: 'Update Reason', getValue: function (obj) {
                return obj[this.property]
            }
        },
        {
            property: 'PatientQuery', label: 'Doc Count', getValue: function (obj) {
                return obj[this.property].DataSourcePatientQuery?.recordCount
            }
        },
        {
            property: 'Person', label: 'Page Count', getValue: function (obj) {
                return obj[this.property].ClientFiles[0]?.pageCount
            }
        },
    ]

    if(loading) return <Spinner />

    if(pendingRequests){
        if(pendingRequests.length === 0) return 'No Pending Requests'
        return <TableContainer component={Paper} style={{marginTop: '30px'}}>

        <Table sx={{minWidth: 650}} size="small">

            <TableHead>
                <TableRow>
                    {headers.map(h => <TableCell key={h.property}>{h.label}</TableCell>)}
                </TableRow>
            </TableHead>
            <TableBody>
                {pendingRequests.map(b => {
                    return <TableRow key={b.id} id={b.id}>
                        {headers.map(h => {
                            return <TableCell key={`${b.id}${h.property}`}>{h.getValue(b)}</TableCell>
                        })}
                    </TableRow>
                })
                }
            </TableBody>
        </Table>
    </TableContainer>
    }
 
}

export default RateChangesTable