import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios'

export const slice = createSlice({
    name: 'billing',
    initialState: {
        loading: false,
        billRuns: [],
        billRun: null,
        error: null
    },
    reducers: {
        loadBillRuns: (state, action) => {
            state.billRuns = action.payload
            state.loading = false
        },
        loadBillRun:(state, action) => {
            state.billRun = action.payload
            state.loading = false
        },
        setLoading: (state) => {
            state.loading = false
        },
        setError: (state, action) => { state.error = action.payload},
        clearError: state => { state.error = null },
    }
})

export const { setLoading, loadBillRuns, setError, clearError, loadBillRun } = slice.actions

export const getBillRunsAsync = () => async dispatch => {
    // dispatch(setLoading())
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_BASE}/v1/Billing`)
        dispatch(loadBillRuns(res.data))
    } catch (err) {
        console.log(err)
        // dispatch(setError(err.response.data.error))
    }
}

export const getBillRunAsync = (id) => async dispatch => {
    // dispatch(setLoading())
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_BASE}/v1/Billing/${id}`)
        dispatch(loadBillRun(res.data))
    } catch (err) {
        console.log(err)
        // dispatch(setError(err.response.data.error))
    }
}

export const createBillRunAsync = ({startDate, endDate}) => async dispatch => {
    // dispatch(setLoading())
    try {
        await axios.post(`${process.env.REACT_APP_API_BASE}/v1/Billing`,{startDate, endDate})
        dispatch(getBillRunsAsync())
    } catch (err) {
        console.log(err)
        // dispatch(setError(err.response.data.error))
    }
}

export const undoBillRunAsync = (id) => async dispatch => {
    // dispatch(setLoading())
    try {
        await axios.delete(`${process.env.REACT_APP_API_BASE}/v1/Billing/${id}`)
        dispatch(getBillRunsAsync())
    } catch (err) {
        console.log(err.response.data.error)
        dispatch(setError(err.response.data.error))
    }
}


export const BillingState = state => state.billing

export default slice.reducer