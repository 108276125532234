import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios'

export const slice = createSlice({
    name: 'report',
    initialState: {
        loading: false,
        batch: null,
        data: [],
        facilities: null,
        networks: null,
        rateOptions: null,
        error: null
    },
    reducers: {
        loadBatch: (state, action) => {
            state.batch = action.payload
            state.loading = false
        },
        loadData: (state, action) => {
            state.data = action.payload
            state.loading = false
        },
        loadFacilities: (state, action) => {
            state.facilities = action.payload
            state.loading = false
        },
        loadNetworks: (state, action) => {
            state.networks = action.payload
            state.loading = false
        },
        setLoading: (state) => {
            state.loading = false
        },
        setRateOptions: (state, action) => {
            state.rateOptions = action.payload
            state.loading = false
        },
        setError: (state, action) => { state.error = action.payload},
        clearError: state => { state.error = null },
    }
})

export const { setLoading, loadBatch, loadData, setRateOptions, setError, clearError, loadFacilities, loadNetworks } = slice.actions

export const getBatchReportAsync = ({batchId,rateId}) => async dispatch => {
    // dispatch(setLoading())
    if(batchId === '') batchId = null
    if(rateId === '') rateId = null

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_BASE}/v1/Reports/$batch`, {batchId,rateId})
        dispatch(loadBatch(res.data))
    } catch (err) {
        console.log(err)
        // dispatch(setError(err.response.data.error))
    }
}

export const getFacilitiesAsync = () => async dispatch => {
    // dispatch(setLoading())
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_BASE}/v1/Reports/$facilities`)
        dispatch(loadFacilities(res.data))
    } catch (err) {
        console.log(err)
        // dispatch(setError(err.response.data.error))
    }
}

export const getNetworksAsync = () => async dispatch => {
    // dispatch(setLoading())
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_BASE}/v1/Reports/$networks`)
        dispatch(loadNetworks(res.data))
    } catch (err) {
        console.log(err)
        // dispatch(setError(err.response.data.error))
    }
}

export const loadRateOptionsAsync = () => async dispatch => {
    dispatch(setLoading())
    const config = {headers: {'Content-Type': 'application/json'}}
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_BASE}/v1/Reports/$rates`, config)
        dispatch(setRateOptions(res.data))
    } catch (err) {
        dispatch(setError(err.response.data.error))
    }
}

export const ReportState = state => state.report

export default slice.reducer