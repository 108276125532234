import { Grid } from '@mui/material'
import Label from "./Label"
import Value from "./Value"
import React, { Fragment } from 'react'

const GridRow = ({data}) => {
    const { label, value } = data

    return (
        <Fragment>
            <Grid item xs={12} sm={2}>
                <Label text={label} />
            </Grid>
            <Grid item xs={12} sm={10}>
                <Value text={value} />
            </Grid>
        </Fragment>
    )
}

export default GridRow