import React, { Fragment } from 'react'
import { useDispatch } from 'react-redux'
// state
import { updateDocServerAsync } from './appHealthSlice'

import Spinner from '../../layouts/Spinner'

// MUI
import { Button } from "@mui/material"

const UpdateDocServerStatus = ({data}) => { 
    const dispatch = useDispatch()

    const handleDeactivate = () => {
        // console.log('Deactivate')
        dispatch(updateDocServerAsync({id:data.id,status:false, field:'active'}))
    }
    const handleActivate = () => {
        // console.log('Activate')
        dispatch(updateDocServerAsync({id:data.id,status:true, field:'active'}))
    }

    if(!data) return <Spinner />
    return <Fragment>
        {data.active &&
        <Button size='small' color='inherit' variant="contained" sx={{textTransform: 'none', backgroundColor: '#c6c5c7'}} onClick={handleDeactivate}>
                De-Activate
            </Button> 
        }
       {!data.active &&
        <Button color='inherit' size='small' variant="contained" sx={{paddingLeft:2.5, paddingRight: 2.5,backgroundColor: '#c6c5c7', textTransform: 'none'}} onClick={handleActivate}>
                Activate
            </Button> 
        }
            
        </Fragment>

}

export default UpdateDocServerStatus