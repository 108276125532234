import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios'

export const slice = createSlice({
    name: 'auth',
    initialState: {
        loading: true,
        isTokenSet: false,
        token: null,
        user: null,
        userLoaded: false
    },
    reducers: {
        setLoading: (state, action) => {
            state.loading = false
            state.isTokenSet = true
            state.token = action.payload
        },
        setUser: (state, action) => {
            state.user = action.payload.user
            state.userLoaded = true
        },
        userNotFound: (state) => {
            state.user = null
            state.userLoaded = true
        }
    }
})

export const { setLoading, setUser, userNotFound } = slice.actions

export const setAuthToken = ({isAuthenticated, getAccessTokenSilently}) => async dispatch => {
    const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUD })
    if (isAuthenticated) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    } else {
        delete axios.defaults.headers.common['Authorization']
    }

    dispatch(setLoading(token))
}

export const setUserAsync = email => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_BASE}/v1/about`, config)
        dispatch(setUser(res.data))
    } catch (err) {
        dispatch(userNotFound())
    }
}

export const AuthState = state => state.auth

export default slice.reducer