import React, { Fragment } from 'react'
import moment from 'moment'


import Spinner from '../../layouts/Spinner'
import DocumentDownloadDetails from './DocumentDownloadDetails'

import Label from '../../widgets/Label'

// MUI
import { Box, Card, Grid } from "@mui/material"

const DocumentDownloads = ({data}) => { 

    if(!data) return <Spinner />
    return <Fragment>
            <Card sx={{ width: '20%' }}>
                <Box sx={{ marginLeft: 2, marginBottom: 2}}>
                    <h3>Document Download Summary</h3>
                    <Grid item xs={12} sm={8}>
                        <Label text={`Downloads Pending: ${data.pendingCount}`} />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Label text={`Currently Processing: ${data.processingCount ? data.processingCount  : '-'}`}  />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Label text={`Last Attempt: ${ data.lastAttempt ? moment(data.lastAttempt).format('YYYY-MM-DD HH:mm') : '-'} `} />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Label text={`Problem Downloads: ${data.errorCount ? data.errorCount : '-'}`}  />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Label text={`Unassigned Downloads: ${data.unassignedCount ? data.unassignedCount : '-'}`}  />
                    </Grid>
                </Box>
                {data.pendingCount > 0 && 
                        <Grid item xs={12} sm={8} sx={{ marginLeft: 1, marginBottom: 1}}>
                            <DocumentDownloadDetails/>
                        </Grid>}
            </Card>
        </Fragment>

}

export default DocumentDownloads