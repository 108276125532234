import React, { useEffect, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fNumber } from '../../utils/formatNumber';

// mui
import { DataGrid, GridToolbar, getGridStringOperators } from '@mui/x-data-grid';
import { Grid } from '@mui/material'

import { ReportState, getNetworksAsync} from './reportSlice'
import Spinner from '../../layouts/Spinner'

import Separator from '../../widgets/Separator'
import Label from '../../widgets/Label'

const NetworksReport = () => {
    const dispatch = useDispatch()

    const { networks } = useSelector(ReportState)

    useEffect(() => {
        dispatch(getNetworksAsync())
    }, [dispatch])

    const columns = React.useMemo( () => {
        const columnsFormat = [
            {field: 'source', headerName: 'Network ID', width: 150,
                renderCell: (params) => {
                    return params.row.source
                }},
            {field: 'display', headerName: 'Network', width: 250,
                renderCell: (params) => {
                    return params.row.display
                }},
            {field: 'returnCount', headerName: '# Documents', width: 150,
                renderCell: (params) => {
                    return fNumber(params.row.returnCount)
                }}
        ]

        return columnsFormat.map((col) => {
            return {
            ...col,
            filterOperators: getGridStringOperators().filter(
                (operator) => operator.value === 'contains' || operator.value === 'equals',
            ),
            };
        })
    }, []);

 

       if(networks){
                return (
                    <Fragment>
                        <h1>Networks Report</h1>
                        <div>
                            <Grid item xs={12} sm={2}>
                                <Label text={`Document count includes all documents returned from network for all queries.`}  />
                            </Grid>
                        </div>
                        <Separator />
                        <div>
                            <div style={{ height: 650, width: '100%' }}>
                                <DataGrid
                                    getRowId={(row) => row?.source + row.returnCount}
                                    components={{
                                        Toolbar: GridToolbar
                                    }}
                                    componentsProps={{
                                        toolbar: {
                                        csvOptions: { disableToolbarButton: false },
                                        printOptions: { disableToolbarButton: true },
                                        },
                                    }}
                                    style={{
                                        backgroundColor: 'white'
                                    }}
                                    initialState={{
                                        sorting: {
                                          sortModel: [{ field: 'source', sort: 'asc' }],
                                        },
                                      }}
                                    rows={networks}
                                    columns={columns}
                                    rowsPerPageOptions={[25,50,100]}   
                                />
                            </div>
                        </div>
                        
                    </Fragment>
                )
            }
        else return <Spinner />

}

export default NetworksReport