import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios'

export const slice = createSlice({
    name: 'dashboard',
    initialState: {
        loading: false,
        monthlyQueries: [],
        weeklyQueries: [],
        monthlyPatients: [],
        weeklyPatients: [],
        yearlyQueries: [],
        // rates: [],
        error: null
    },
    reducers: {
        loadDashboard: (state, action) => {
            state.monthlyQueries = action.payload.monthlyQueries
            state.monthlyPatients = action.payload.monthlyPatients
            state.weeklyPatients = action.payload.weeklyPatients
            state.weeklyQueries = action.payload.weeklyQueries
            state.yearlyQueries = action.payload.yearlyQueries
            // state.rates = action.payload.rates
            state.loading = false
        },
        setLoading: (state) => {
            state.loading = false
        },
        setError: (state, action) => { state.error = action.payload},
        clearError: state => { state.error = null },
    }
})

export const { setLoading, loadDashboard, setError, clearError } = slice.actions

export const getDashboardAsync = () => async dispatch => {
    // dispatch(setLoading())
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_BASE}/v1/Dashboard`)
        dispatch(loadDashboard(res.data))
    } catch (err) {
        console.log(err)
        // dispatch(setError(err.response.data.error))
    }
}


export const DashboardState = state => state.dashboard

export default slice.reducer