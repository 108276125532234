import React, { useEffect, Fragment } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

// components
import Spinner from '../../layouts/Spinner'

// context
import { OrgState, getOrganizationAsync, getRatesAsync, getBillsAsync,getPeopleAsync } from "./orgSlice"

// mui
import {
    Typography,
    Grid
} from "@mui/material";
import EditOrganization from './EditOrganization'
import Users from './Users'
import OrganizationBilling from './OrganizationBilling'
import RateCodes from './RateCodes'
import OrganizationWidgets from './OrganizationWidgets'

import Separator from '../../widgets/Separator'
import GridRow from '../../widgets/GridRow'

const OrganizationHeader = ({organization}) => {
    if (!organization) return "<p>No Data</p>"
    const { name } = organization

    return <Typography variant='h5' component='h5' style={{marginTop: 10}}>
                <Grid container spacing={2}>
                {name}
                <EditOrganization organization={organization} /> 
                </Grid>
            </Typography>


}

const OrganizationDetail = () => {
    const dispatch = useDispatch()
    const { id } = useParams()

    const { organization, loading, rates, bills, people } = useSelector(OrgState)

    useEffect(() => {
        dispatch(getOrganizationAsync(id))
    }, [dispatch, id])

    useEffect(() => {
        if(organization) {
            dispatch(getRatesAsync(id))
            dispatch(getBillsAsync(id))
            dispatch(getPeopleAsync(id))
        }
    }, [organization, dispatch, id])
  
    if (loading) return (<Spinner />)

    const { name, street, city, state,postalCode, oAuthClientId, apiSub, apiUserId, isActive  } = organization

    const elements = [
        {label: 'Name', value: name},
        {label: 'Address', value:  `${street ? street : ''} ${city ? city : ''} ${state ? state:''} ${postalCode ? postalCode:''}`},
        {label: 'Auth ID', value: oAuthClientId},
        {label: 'API Sub', value: apiSub},
        {label: 'API User ID', value: apiUserId},
        {label: 'Active', value: isActive ? 'Yes' : 'No'}
    ]
 
    return (
        <Fragment>
            <div style={{marginLeft: 10, marginTop: "30px"}}>
                <OrganizationHeader organization={organization} />

                <Separator />

                <Grid container spacing={2}>
                    <Grid container spacing={0} style={{marginTop: 10, marginLeft: 20}}>
                        <Grid item xs={12} xl={6}>
                            {elements.map(e => (
                                <GridRow key={e.label} data={e} />
                            ))}
                        </Grid>
                        <Grid item xs={12} xl={6}>
                            <OrganizationWidgets patients={people}/>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} xl={6}>
                        <Users organization={organization}/>
                    </Grid>
                    <Grid item xs={12} xl={6}>
                        <RateCodes rates={rates}/>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} xl={6}>
                        <OrganizationBilling Bills={bills}/>
                    </Grid>
                </Grid>
            </div>

        </Fragment>
    )
}

export default OrganizationDetail

